.productdisplay {
    display: flex;
    margin: 0px 170px;
    width: 95%;
    margin-left: 75px;
}

.productdisplay-left {
    display: flex;
    gap: 17px;
}

.productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.productdisplay-img-list img {
    height: 161px;
}

.productdisplay-main-img {
    width: 586px;
    height: 692px;
}

.productdisplay-right {
    margin: 0px 70px;
    display: flex;
    flex-direction: column;
}

.productdisplay-right h1 {
    color: #3d3d3d;
    font-size: 40px;
    font-weight: 700;
}

.productdisplay-right-star {
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 16px;
}

.productdisplay-right-prices {
    display: flex;
    margin: 20px 0px;
    gap: 30px;
    font-size: 24px;
    font-weight: 700;
}

.productdisplay-right-price-old {
    color: #818181;
    text-decoration: line-through;
}

.productdisplay-right-price-new {
    color: #ff4141;
}

.productdisplay-right-size h1 {
    margin-top: 15px;
    color: #656565;
    font-size: 20px;
    font-weight: 600;
}

.productdisplay-right-sizes {
    display: flex;
    margin: 15px 0px;
    gap: 20px;
}

.productdisplay-right-sizes div {
    padding: 18px 24px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
}

.productdisplay-right button {
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #ff4141;
    margin-top: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.productdisplay-right button:hover {
    background: #fc7373;
    color: white;
}

.productdisplay-right-description button {
    width: 200px;
    height: 58px;
    display: flex;
    align-items: center;
    padding: 15px;
    margin-top: 10px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: white;
    cursor: pointer;
    gap: 10px;
}

.productdisplay-right-description button:hover {
    background-color: #656565;
    color: #dedede;
}

.trailer-button img {
    height: 25px;
    width: 35px;
}   

.productdisplay-right-category,
.productdisplay-right-movie-stars,
.productdisplay-right-movie-director,
.productdisplay-right-movie-writer,
.productdisplay-right-movie-runtime
{
    margin-top: 10px;
}

.productdisplay-right-category span,
.productdisplay-right-movie-stars span,
.productdisplay-right-movie-director span,
.productdisplay-right-movie-writer span,
.productdisplay-right-movie-runtime span{
    font-weight: 600;
}

.productdisplay-buttons {
    display: flex;
    gap: 20px;
}