.newsletter {
    width: 65%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px 140px;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #063daa 0%, #e1ffea22 60%);
    gap: 30px;
}

.newsletter h1 {
    color: #454545;
    font-size: 55px;
    font-weight: 600;
}

.newsletter p {
    color: #454545;
    font-size: 20px;
}

.newsletter div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 730px;
    height: 70px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
}

.newsletter input {
    width: 500px;
    margin-left: 30px;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 16px;
}

.newsletter button {
    width: 210px;
    height: 70px;
    border-radius: 80px;
    background: black;
    color: white;
    font-size: 16px;
    cursor: pointer;
}