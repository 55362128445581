.shopcategory-indexSort {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  padding: 20px;
}

.shopcategory-sort-wrapper {
  padding: 10px 20px;
  border-radius: 40px;
  margin-left: 10px; 
  display: flex;
  align-items: center; 
}

.shopcategory-sort {
  padding: 10px 20px;
  border-radius: 40px;
  display: flex;
  align-items: center; 
}

.shopcategory-sort-wrapper p{
  font-weight: 900;
  margin: 15px;
}

.shopcategory-sort select {
  margin-left: 5px; 
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  width: 150px; 
}

.shopcategory-sort select:hover {
  background-color: #656565;
  color: #dedede;
}

.shopcategory-search {
  border-radius: 10px;
  box-shadow: 0 0 8px #ddd;
  padding: 10px;
}

.shopcategory-products {
  margin: 20px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 80px;
  gap: 20px;
}

.shopcategory-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.shopcategory-pagination button {
  font-size: 15px;
  margin: 0 10px;
  color: #656565;
  border-radius: 30px;
  padding: 15px 25px;
  background: #dedede;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.shopcategory-pagination button:hover {
  background: #656565;
  color: #dedede;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.shopcategory-pagination button.active {
  border: #656565 1px solid;
  color: #656565;
}

.shopcategory-pagination button.active:hover {
  color: #dedede;
}

.input-wrapper {
  position: relative;
  background-color: white;
  width: 15%;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0 15px;
  align-self: center;
  box-shadow: 0 0 8px #ddd;
  display: flex;
  align-items: center;
}

input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
}

input:focus {
  outline: none;
}

.search-bar,
.results-list {
  box-sizing: border-box;
  width: 100%;
}

.results-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  margin-top: 1rem;
  max-height: 100px;
  overflow-y: scroll;
  padding-left: 5px;
  margin-top: 3px;
  border-radius: 10px;
}

.results-list a:hover {
  background-color: #656565;
}