.offers {
    width: 80%;
    height: 60vh;
    display: flex;
    margin: auto;
    padding: 60px 140px;
    margin-bottom: 50px;
    background: linear-gradient(180deg, #063daa 0%, #e1ffea22 60%);   
}

.offers-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.offers-left h1 {
    color: #171717;
    font-size: 80px;
    font-weight: 600;
}

.offers-left p {
    color: #171717;
    font-size: 22px;
    font-weight: 600;
}

.offers-check-btn {
    width: 200px;
    height: 40px;
    border-radius: 35px;
    background: #ff4141;
    border: none;
    color: white;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.offers-check-btn a{
    text-decoration: none;
    color: white;
}

.offers-check-btn:hover {
    background: #fc7373;
    color: white;
}

.offers-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 50px;
}