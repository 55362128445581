.item {
    width: 350px;
}

.item p {
    margin: 6px 0px;
}

.item img {
    border: solid 1px black;
}

.item-prices {
    display: flex;
    gap: 20px;
}

.item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}