.loginsingup {
    height: 80vh;
    background: #063daa;
    padding: 100px;
}

.loginsignup-container {
    width: 580px;
    height: 600px;
    background: white;
    margin: auto;
    padding: 40px 60px;
}

.loginsignup-container h1 {
    margin: 20px 0px;
}

.loginsignup-fields {
    display: flex;
    flex-direction: column;	
    gap: 29px;
    margin-top: 30px;
}

.loginsignup-fields input {
    height: 72px;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #c9c9c9;
    outline: none;
    color: #5c5c5c;
    font-size: 18px;
}

.loginsignup-terms{
    padding-top: 10px;
}

.loginsignup-container button {
    width: 580px;
    height: 72px;
    color: white;
    background: #ff4141;
    margin-top: 30px;
    border: none;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
}

.loginsignup-login {
    margin-top: 20px;
    color: #5c5c5c;
    font-size: 18px;
    font-weight: 500;
}

.loginsignup-login span {
    color: #ff4141;
    font-weight: 600;
}