.navbar{
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0 1px 3px -2px black;
}

.navbar a{
    text-decoration: none;
}

.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-logo p{
    color: #171717;
    font-size: 38px;
    font-weight: 600;
}

.nav-logo-img {
    max-height: 80px; 
    height: auto; 
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 50px;
    color: #626262;
    font-size: 20px;
    font-weight: 500;
    width: 100px;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}

.nav-menu hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}

.nav-login-cart{
    display: flex;
    align-items: center;
    gap: 45px;
}

.nav-login-cart button {
    width: 157px;
    height: 58px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: white;
    cursor: pointer;
}

.nav-login-cart button:hover {
    background-color: #656565;
    color: #dedede;
}

.nav-login-cart button:active {
    background: #f3f3f3;
    color: #656565;
}

.nav-cart-count {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}
